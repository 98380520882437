.Profile-container-main{
    padding-top: 80px;
 }
 
 .Profile-heading{
     text-align: center;
     font-family: sans-serif;
     text-transform: uppercase;
     font-size: 36px;
     letter-spacing:2px;
     font-weight: 500;
     padding-bottom: 30px;
     background-color:#fff;
     
}

.profile-image-container-main{
    display: flex;
    justify-content:space-around;
    align-items:center;
    height:100%;
    width:100%;
    padding: 20px;
    background-color: #d2dbdd;

}

@media (max-width:935px){
    .profile-image-container-main{
        display:block;
        justify-content:space-between;
        align-items:center;
        column-gap: 20px;
    }
    
    
    
}




