nav {
  background: white;
  padding-right: 20px;
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
  color: black;
  position: relative;
  z-index: 99;
  position: fixed;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
}

.nav-menu img {
  height: 80px;
  border-radius: 50px;
  padding: 5px;
}

.nav-menu {
  display: flex;
  align-items: center;
}
.nav-menu{
  text-align: center;
}

.menu-list {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.menu-list li a {
  text-decoration: none;
  color: black;
  font-weight: 600;
  padding: 5px 10px;
  margin-left: 20px;
}
.menu-list li a:hover,
.menu-list li a.active {
  background-color: #faa42f;
  cursor: pointer;
  color: white;
  transition: all 1 ease-in-out;
  border-radius: 20px;
  /* padding:10px;  */
}
.menu-icon {
  display: none;
}
.fas {
  cursor: pointer;
}
.menu-icon .fa-times {
  transform: rotate(360deg);
  transition: all 0.2s ease-in-out;
}
.menu-icon .fa-bars {
  transform: rotate(180deg);
  transition: all 0.2s ease-in-out;
}



.nav-bttn{
  align-items: center;
 
}

.nav-bttn button {
  border-radius:50px;
  padding: 5px;
  width:200px;
  margin-bottom: 5px;
  box-shadow: 5px 3px rgb(0, 217, 255);
  font-weight:900;
}

.nav-bttn button:hover {
  background-color: orange !important;
  color: black !important;
  transform: scale(1.05,1.05);
}



@media screen and (max-width: 850px) {
  .logo {
    height: 70px;
    width: 240px;
  }
  .menu-list li a {
    font-size: 14px;
    margin-left: 10px;
  }
}

@media screen and (max-width: 800px) {
  .menu-icon {
    display: block;
    font-size: 30px;
    text-align: center;
    margin-top: 10px;
  }
  .menu-list {
    flex-direction: column;
    width: 100%;
    position: absolute;
    background-color: #faa52fc5;
    padding: 20px;
    right: 0;
    top: 70px;
    transition: all 0.5s ease-in-out;
  }
  .menu-list li {
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
  }
  .menu-list li:last-child {
    margin-bottom: 0px;
  }
  .menu-list li a:hover,
  .menu-list li a.active {
    background-color: white;
    color: black;
  }

  .menu-list.close {
    right: -100%;
    transition: all 0.5s ease-in-out;
  }
}


@media(max-width:600px){
  .nav-menu h3{
    font-size: small;
  }
  .nav-menu button{
    font-size: small;
    width:150px;
  }
}
