.Profile-card-img img{
    height:300px;
    width:300px;
    display:block; 
    justify-content: center;
    align-items: center;
}
.Profile-cards:hover{
    box-shadow: 0px 30px 18px -8px rgba(0,0,0,0.1);
    transform: scale(1.05,1.05);
}

button{
    background-color:black;
    color:white;
    border: 1px solid black;
    align-items: center;
   width:300px;
}


.Profile-card-img button{
    opacity: 0;
}

.Profile-card-img:hover button{
    opacity: 1;
}

@media (max-width:935px){
    .Profile-card-img{
        margin-bottom: 30px;
        align-items: center;
        margin-left: 30%;
    }

    .Profile-cards{
        align-items: center;
    }
}


@media (max-width:665px){
    .Profile-card-img{
        margin-left: 25%;
    }
}

@media (max-width:540px){
    .Profile-card-img{
        margin-left: 20%;
    }

}

@media (max-width:450px){
    .Profile-card-img{
        margin-left: 10%;
    }
    .Profile-card-img img{
        width:250px;
    }

}

@media (max-width:385px){
    .Profile-card-img{
        margin-left: 5%;
    }

}


@media (max-width:340px){
    .Profile-card-img{
        margin-left: 0px;
    }
}
