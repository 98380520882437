.home-main-container{
    padding-top: 80px;
}

.home-header{
    background-image: url("../../images/Youtuber.jpg");
    width:100%;
    height:100vh;
    background-repeat: no-repeat;
    background-size:cover;
}

.home-header-text{
    padding-top:300px;
    color: #fff;
    justify-content: center;
    text-align: center;
    text-shadow: 3px 3px black;
}

.home-header-text h5 {
    font-size: 2rem;
}


.home-header-icons{
    padding-top:20px;
    display: flex;
    justify-content:space-between;
    column-gap: 30px;
    align-items: center;
}

.home-header-ul{
    display: flex;
    column-gap: 10px;
    list-style: none;
}

.home-header-ul li a{
    width: 50px;
    height: 50px;
    border: 2px solid white;
    border-radius: 50%;
    color: #FFF;
    font-size: 2rem;
    cursor: pointer;
    justify-content:center;
    align-items: center;
    padding:5px;
    display: flex;
}

.home-header-ul a:hover {
    background-color: blue;
    transition: all .3s;
}




.scroll-down {
    height: 50px;
    width: 30px;
    border: 2px solid white;
    position: absolute;
    left: 50%;
    bottom: 20px;
    border-radius: 50px;
    cursor: pointer;
}

.scroll-down::before,
.scroll-down::after {
    content: "";
    position: absolute;
    top: 20%;
    left: 50%;
    height: 10px;
    width: 10px;
    transform: translate(-50%, -100%) rotate(45deg);
    border: 2px solid white;
    border-top: transparent;
    border-left: transparent;
    animation: scroll-down 1s ease-in-out infinite;
}

.scroll-down::before {
    top: 30%;
    animation-delay: 0.3s;
    /* animation: scroll-down 1s ease-in-out infinite; */
}

@keyframes scroll-down {
    0% {
        /* top:20%; */
        opacity: 0;
    }

    30% {
        opacity: 1;
    }

    60% {
        opacity: 1;
    }

    100% {
        top: 90%;
        opacity: 0;
    }
}



.home-mid{
    padding-top: 30px;
    display:flex;
    justify-content:space-around;
    align-items: center;
    text-align: center;
}

.home-mid-about{
    padding-top: 30px;
    display:flex;
    justify-content:space-around;
    align-items: center;
    text-align: center;
}

.header-bottom-border{
    width: 200px;
    border-bottom: 1px solid #2196f3;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.header-bottom-div-one-ul{
    list-style: none;
}

.home-mid-pic img{
    width: 300px;
    height: 300px;
    border-radius: 50%;
    border: 2px solid gray;
    background-color: black;
    animation: pulse 2s infinite;
}

/* animation */
.pulse:hover {
    animation: none;
  }
  
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.4);
    }
    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.4);
      box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.4);
    }
    70% {
        -moz-box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
        box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
    }
  }

/* ANIMATIONA */

.mb{
    margin-bottom: 50px;
}
.home-mid-skill{
    width:400px;
    text-align:center;
    align-items: center;
    justify-content: center;
}



@media (max-width:1124px){
.home-mid-about{
    display:block;
}
.home-mid-skill{
    width:100%;
}
}