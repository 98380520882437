.input-text {
    width: 100%;
    box-sizing: border-box;  
    border: 2px solid black;
    font-size:15px;
    padding: 10px;
    

}
.form-text{
    font-size:15px;
    text-align:start;
}

textarea {
    width: 80%;
    height: 100%;
    padding: 15px 22px;
    box-sizing: border-box;
    border: 2px solid black;
    border-radius: 2px;
    resize: none; 
    font-size:15px; 
}

input[type=submit] {
    background: transparent;
    padding: 10px 25px;
    color: #282528 !important;
    font-weight: 400;
    display: inline-block;
    border: 1px solid #282528;
    border-radius: 50px;
    transition: .4s;
    outline: 0;
    font-size:15px;
    
}

.button:hover{
    background-color: black !important;
   color:white !important;
}


